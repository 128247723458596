import {
  getCountryList,
  createCountryList,
  updateCountry,
  deleteCountry,
} from "../services/api";
import { message } from "antd";

export default {
  namespace: "countries",

  state: {
    add: { count: 0 },
    edit: { count: 0 },
    fileUp: { count: 0 },
    del: { count: 0 },
  },

  subscriptions: {
    setup({ dispatch, history }) {},
  },

  effects: {
    *CountryList({ payload }, { call, put }) {
      const response = yield call(getCountryList, payload);
      if (!response.status) {
        message.error(response.msg || response.message || response.err, 5);
      }
      yield put({ type: "list", ...response });
    },
    *CountryAdd({ payload }, { call, put }) {
      const response = yield call(createCountryList, payload);
      if (!response.status) {
        message.error(response.msg || response.message || response.err, 5);
      }
      if (response.status) {
        message.success(" Country Created!", 5);
      }
      yield put({ type: "add", ...response });
    },
    *CountryEdit({ payload }, { call, put }) {
      const response = yield call(updateCountry, payload);
      if (!response.status) {
        message.error(response.msg || response.message || response.err, 5);
      }
      if (response.status) {
        message.success(" Country Updated!", 5);
      }
      yield put({ type: "edit", ...response });
    },
    *deleteCountry({ payload }, { call, put }) {
      const response = yield call(deleteCountry, payload);
      if (!response.status) {
        message.error(response.msg || response.message || response.err, 5);
      }
      if (response.status) {
        message.success(" Country Deleted!", 5);
      }
      yield put({ type: "del", ...response });
    },
    *clearAction({ payload }, { call, put }) {
      yield put({ type: "clear" });
    },
  },

  reducers: {
    list(state, action) {
      return { ...state, list: action };
    },
    add(state, action) {
      action.count = state.add.count + 1;
      return { ...state, add: action };
    },
    edit(state, action) {
      action.count = state.edit.count + 1;
      return { ...state, edit: action };
    },
    del(state, action) {
      action.count = state.del.count + 1;
      return { ...state, del: action };
    },
    clear(state, action) {
      return { ...state, edit: { count: 0 }, del: { count: 0 } };
    },
  },
};
