import React, { useState, useEffect } from "react";
import { Form, Input, Select, Row, Col, Button, message } from "antd";
import axios from "axios";

const baseUrl = process.env.REACT_APP_ApiUrl;

const { Option } = Select;

const CityComponent = (props) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);

  const [form] = Form.useForm(); // Initialize the form instance

  useEffect(() => {
    if (props.citydata.city_id) {
      setSelectedCountry(props.citydata.country_id);
      form.setFieldsValue({
        country: props.citydata.country_id,
        state: props.citydata.state_id,
        cityName: props.citydata.city,
      });
    } else {
      form.resetFields();
    }
  }, [props.citydata, form]);

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      fetchStatesByCountry(selectedCountry);
    }
  }, [selectedCountry]);

  const fetchCountries = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/getAlldata/country`);
      setCountries(response.data.data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const fetchStatesByCountry = async () => {
    try {
      if (selectedCountry) {
        const response = await axios.post(`${baseUrl}/api/getData/state`, {
          country_id: selectedCountry,
        });
        console.log("API Response:", response.data.data);

        const allStates = response.data.data;
        console.log("All States:", allStates);

        const filteredStates = allStates.filter(
          (state) => state.country_id._id === selectedCountry
        );

        console.log("Filtered States:", filteredStates);

        setStates(filteredStates);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const handleCountryChange = (value) => {
    console.log("handleCountryChange=====", value, "state");
    setSelectedCountry(value);
    setSelectedState(null);
  };

  const handleStateChange = (value) => {
    setSelectedState(value);
  };

  // const handleCityNameChange = (e) => {
  //   // No need to manage cityName in the local state
  // };

  // const handleCreateCity = async () => {
  //   try {
  //     const values = await form.validateFields(); // Validate form fields

  //     const requestData = {
  //       state_id: values.state,
  //       city_name: values.cityName,
  //     };

  //     let response;

  //     if (typeof props.citydata.city_id !== undefined) {
  //       requestData["id"] = props.citydata.city_id;
  //       // requestData.id = props.citydata.city_id;

  //       console.log("request", requestData);

  //       // Update existing city
  //       response = await axios.post(`${baseUrl}/api/city`, requestData);

  //       if (response.data._id) {
  //         message.success("City updated successfully");
  //       }
  //     } else {
  //       // Create new city
  //       response = await axios.post(`${baseUrl}/api/city`, requestData);
  //       if (response.data._id) {
  //         message.success("City create successfully");
  //       }
  //     }

  //     if (props.returnSuccess) {
  //       if (props.citydata._id) {
  //         response.data._id = props.citydata
  //           ? props.citydata._id
  //           : response.data._id;
  //       }
  //       props.returnSuccess(response.data);
  //     }

  //     form.resetFields(); // Clear form fields after submission
  //   } catch (error) {
  //     console.error("Error adding/updating city:", error);
  //     message.error("Duplicate Entry");
  //   }
  // };

  const handleCreateCity = async () => {
    try {
      const values = await form.validateFields(); // Validate form fields

      const requestData = {
        state_id: values.state,
        city_name: values.cityName,
      };

      let response;

      if (props.citydata.city_id !== undefined) {
        // Corrected condition
        requestData.id = props.citydata.city_id;

        // Update existing city
        response = await axios.post(`${baseUrl}/api/city`, requestData);

        if (response.data._id) {
          message.success("City updated successfully");
        }
      } else {
        // Create new city
        response = await axios.post(`${baseUrl}/api/city`, requestData);
        if (response.data._id) {
          message.success("City created successfully"); // Changed message for create
        }
      }

      if (props.returnSuccess) {
        if (props.citydata._id) {
          response.data._id = props.citydata
            ? props.citydata._id
            : response.data._id;
        }
        props.returnSuccess(response.data);
      }

      form.resetFields(); // Clear form fields after submission
    } catch (error) {
      console.error("Error adding/updating city:", error);
      message.error("Duplicate Entry");
    }
  };

  return (
    <div>
      <Form form={form} layout="inline">
        <Row>
          <Col>
            <Form.Item
              label="Country"
              name="country"
              rules={[
                { required: true, message: "Please enter a country name" },
                {
                  whitespace: true,
                  message: "Country name should not be just white spaces",
                },
              ]}
            >
              <Select
                placeholder="Select a country"
                onChange={handleCountryChange}
                style={{ width: 200 }}
              >
                {/* {countries.map((country) => (
                  <Option key={country._id} value={country._id} >
                    {country.country_name}
                  </Option>
                ))} */}

                {countries.map((country) => {
                  let countryId = props.country_id ? props.country_id : "";
                  return (
                    <Option
                      key={country._id}
                      value={country._id}
                      // selected={countryId == country._id ? true : false}
                    >
                      {country.country_name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="State"
              style={{ marginLeft: "20px" }}
              name="state"
              rules={[{ required: true, message: "Please enter a state name" }]}
            >
              <Select
                placeholder="Select a state"
                style={{ width: 200 }}
                onChange={handleStateChange}
              >
                {states.map((state) => (
                  <Option key={state._id} value={state._id}>
                    {state.state_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="City"
              style={{ marginLeft: "27px" }}
              name="cityName"
              rules={[{ required: true, message: "Please enter a city name" }]}
            >
              <Input style={{ width: "100%" }} placeholder="Enter City" />
            </Form.Item>
          </Col>
          <Form.Item>
            <Button type="primary" onClick={handleCreateCity}>
              {/* <Button type="primary"> */}
              {/* Create City */}
              {props.citydata.city_id ? "Update City" : "Create City"}
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

export default CityComponent;
