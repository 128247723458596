import React, { useState, useEffect } from "react";
import CityComponet from "./add-edit/addEdit";
import {
  Input,
  Button,
  Select,
  Col,
  Modal,
  Table,
  Row,
  Popconfirm,
  Typography,
} from "antd";
import axios from "axios";

const baseUrl = process.env.REACT_APP_ApiUrl;

const { Option } = Select;
const { Text } = Typography;

const CityComponent = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedcity, setSelectedcity] = useState(null);
  const [city, setcity] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [Pagesize, setPagesize] = useState(10);

  useEffect(() => {
    setCityData(city); // Update cityData with the fetched city data
  }, [city]);

  useEffect(() => {
    if (searchText == "") {
      setCityData(city);
    } else {
      setCityData(cityData);
    }
  }, [city, cityData]);
  const fetchcity = async (page = currentPage, limit = Pagesize) => {
    try {
      const response = await axios.post(`${baseUrl}/api/getAll/city`, {
        page,
        limit,
        // page: currentPage, // Include the current page in the request body
        // limit: 10, // Assuming you want to show 10 items per page
      });

      setcity(response.data.data);
      setTotalPages(Math.ceil(response.data.count / limit));
    } catch (error) {
      console.error("Error fetching city:", error);
    }
  };
  useEffect(() => {
    fetchcity(); // Fetch all city initially
  }, [currentPage]);

  const handleSuccess = (data) => {
    console.log("data", data);
    setIsModalVisible(false);
    fetchcity();

    // if (data._id) {
    //   // setSelectedCountry(data.id);

    //   setcity((pre) =>
    //     pre.map((city) => (city._id === data._id ? { ...city, ...data } : city))
    //   );
    // }
    // if (data.id) {
    //   setcity((pre) => {
    //     return [...pre, data];
    //   });
    // }
  };

  const showModal = (citys) => {
    // console.log(citys);
    const citydata = {
      city_id: citys._id,
      city: citys.city_name,
      country_id:
        citys.state_id?.country_id?._id && citys.state_id.country_id._id,
      // country_id: citys.state_id.country_id._id,

      // state_id: citys.state_id._id,
      state_id: citys.state_id && citys.state_id._id,
    };

    setIsModalVisible(true);
    console.log(isModalVisible, "show");

    setSelectedcity(citydata);
  };

  const handleOk = () => {
    setSelectedcity(null);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setSelectedcity(null);
    setIsModalVisible(false);
  };
  const handleDelete = (record) => {
    console.log("Delete", record.id);
    axios
      .delete(`${baseUrl}/api/delete/city`, { data: { id: record.id } })
      .then(() => {
        setcity((pre) => pre.filter((city) => city.id !== record.id));
      })
      .catch((error) => {
        console.error("Error deleting country:", error);
      });
  };

  const columns = [
    {
      title: "Country",

      key: "country_name",
      render: (text, data) => {
        // console.log(
        //   "showcountrydata name ",
        //   data.state_id.country_id.country_name
        // );
        // return <span>{data?.state_id?.country_name}</span>
        return <span>{data?.state_id?.country_id?.country_name}</span>;
      },
    },
    {
      title: "State",

      key: "state_name",
      render: (text, data) => {
        return <span>{data?.state_id?.state_name}</span>;
      },
    },
    {
      title: "City Name", // New column for city name
      dataIndex: "city_name", // Assuming the key in your data is "city_name"
      key: "city_name",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <span>
          <Button type="link" onClick={() => showModal(record)}>
            Edit
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this country?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ];
  const handleFilter = (value) => {
    const trimmedValue = value.trim();
    setSearchText(trimmedValue);
    const filteredCity = city.filter(
      (citys) =>
        citys.city_name.toLowerCase().includes(searchText.toLowerCase()) ||
        citys.state_id.state_name
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        citys.state_id.country_id.country_name
          .toLowerCase()
          .includes(searchText.toLowerCase())
    );

    console.log("countries ====----- :", city);
    // setCountries(filteredCountries);
    setCityData(filteredCity);
  };
  const handleShowSizeChange = (current, newSize) => {
    const newPage = Math.floor(((current - 1) * Pagesize) / newSize) + 1;

    setCurrentPage(newPage);
    setPagesize(newSize);

    fetchcity(newPage, newSize);
  };

  return (
    <div>
      <Row justify="space-between" style={{ marginBottom: "10px" }}>
        <Col>
          <Input
            placeholder="Search City name"
            value={searchText}
            onChange={(e) => handleFilter(e.target.value)}
          />
        </Col>
        <Col>
          <Button type="primary" onClick={showModal}>
            Add
          </Button>
        </Col>
      </Row>
      <Modal
        title=""
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <CityComponet returnSuccess={handleSuccess} citydata={selectedcity} />
      </Modal>

      <Table
        dataSource={cityData}
        columns={columns}
        pagination={{
          position: ["bottomLeft"],
          showTotal: (total, range) => (
            <Text type="secondary">{`Showing ${range[0]}-${range[1]} of ${total}`}</Text>
          ),
          showSizeChanger: true,
          responsive: true,
          onShowSizeChange: handleShowSizeChange,
          pageSizeOptions: ["20", "30", "40", "50", "100", "200", "500"],
          pageSize: Pagesize,
          current: currentPage,
          total: totalPages * Pagesize,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setPagesize(pageSize);
            // fetchStates(page, pageSize);
          },
        }}
        // pagination={false}
        // pagination={{
        //   pageSize: 10,
        //   current: currentPage,
        //   total: totalPages * 10,
        //   onChange: (page) => setCurrentPage(page),
        // }}
      />
    </div>
  );
};

export default CityComponent;
