import React, { useState, useEffect, useRef, Fragment,useCallback } from 'react';
import { Row, Col, Empty, Modal, Card, Typography, Alert, Form, Input, Checkbox, Button, Space, Upload, Dropdown, Menu, Select, notification, Transfer, DatePicker, Avatar, message, InputNumber} from 'antd';
import { LeftOutlined, LoadingOutlined, EditOutlined, CloseOutlined, PlusOutlined, MinusCircleOutlined, ConsoleSqlOutlined } from '@ant-design/icons';
import UploadImages from '../../../components/sharing/upload-images'
import CropImage from '../../../components/sharing/crop-image'
import TextEditor from '../../../components/sharing/text-editor'
import moment from 'moment';
import { connect } from 'dva'; 
import styles from './style.less'; 
import { getSubCatbyCategory } from '../../../services/api';
// import { RMIUploader } from "react-multiple-image-uploader";
import MultiImageInput from 'react-multiple-image-input';
import HTMLDecoderEncoder from 'html-encoder-decoder';
import audios from '../../../models/audios';
import ReactAudioPlayer from 'react-audio-player'; 
import { useJsApiLoader,GoogleMap,LoadScript,Marker,Autocomplete  } from '@react-google-maps/api' ; 
import GooglePlacesAutocomplete  from 'react-places-autocomplete';
import {
	geocodeByAddress,
	geocodeByPlaceId,
	getLatLng,
  } from 'react-places-autocomplete';

// import GoogleMapReact from 'google-map-react';
//import { GoogleMap, StandaloneSearchBox, Marker } from "@react-google-maps/api";



const { Text } = Typography;
const { TextArea } = Input;
const timestemp = (new Date()).getTime();
const { RangePicker } = DatePicker;
const formItemLayout = { labelCol: { xs: { span: 24, }, sm: { span: 24, }, } };
const baseUrl = process.env.REACT_APP_ApiUrl  ;


const AnyReactComponent = ({ text }) => <div>{text}</div>;

const AddEditAudio = props => {

	const [form] = Form.useForm();
	const { dispatch } = props;
	const [Inquiry, setInquiry] = useState('');
	const [carId, setCarId] = useState('');
	const [latLng, setLatLng] = useState({});
	const [value, setValue] = useState(null);
	const [autocomplete, setAutocomplete] = useState() ;
	const [audioFile, setAudioFile] = useState(null) ;
	const [audioImage, setAudioImage] = useState(null) ;
	const [address, setAddress] = useState('') ;

	const [errors, setErrors] = useState({}) ;

	const [audio,setAudio] = useState(null);
	const [playing, setPlaying] = useState(false);
  



	const searchRef = useRef() ;
	
	
	const [count, setCount] = useState(0)
	
	
	const {isLoaded} = useJsApiLoader({ googleMapsApiKey:'AIzaSyC2zSMtN-8HBUliSasZEV5fM2-INmPmIUk' });

	useEffect(() => {
		let unmounted = false;
		window.scroll(0, 0);
		//props.dispatch({ type: 'blogsCategory/blogsCategoryList' });
		// props.dispatch({ type: 'category/categoryList' });
		
		if (props.match.params.id) {
			DetailFun(props.match.params.id)
		} else {
			form.resetFields();
		}
		return () => { unmounted = true; }
	}, [])

	
	useEffect(()=>{
		
		navigator.geolocation.getCurrentPosition(function(position) {

			const latitudeLongitude = {

				lat:position.coords.latitude,
				lng:position.coords.longitude
			}
			setLatLng(latitudeLongitude);
			
		});

		
	},[])


	useEffect(() => {
		
		let unmounted = false;
		
		if(props.audios.add){
			dispatch({ type: 'audios/clearAction'});
			
			if(props.audios.add.message){
				props.history.push('/audios');
			
			}
		}
		
		if(props.audios.edit){
			dispatch({ type: 'audios/clearAction'});
			if(props.audios.edit.message){
				props.history.push('/audios');
			
			}
		}
		//console.log('detail he ',props.audios.detail.data);
		if(props.audios && props.audios.detail ){
			if(!props.audios?.detail?.data?.length > 0 ){
				return
			}
			
			
			let data = props.audios?.detail?.data[0] ;
			console.log('ide he ',data._id) ;
			const coordinates = {
				lat:data.location.coordinates[1],
				lng:data.location.coordinates[0]
			}
			setLatLng(coordinates);
			
			form.setFieldsValue({
				['audio_name']: data.audio_title, 
				['location']: data.location_name, 
				['_id']: data._id,
				['description']: data.description,
				['isActive']: data.isActive ,
				['isPaid']: data.isPaid ,
			
				
			
			})
		}else {
			form.resetFields();
		}

		
		return () => { unmounted = true; }
	}, [props.audios])

	

	const DetailFun = (id) => {
		props.dispatch({ type: 'audios/detailAudio', payload: id });
	}
	
	const cancelFun = () => {
		form.resetFields();
		props.history.push('/audios');
	}


	const onAudioImageChange = event => {
		
		setAudioImage(event.target.files[0])
		const file = event.target.files[0];
		
		if(!(file.type =='image/jpg' || file.type =='image/jpeg' || file.type =='image/png' )){
			
			setErrors({...errors,imageTypeError:'Please select image file type'});

		}else{
			setErrors({...errors,imageTypeError:''});
		}
	  };

	  const onAudioChange = event => {
		  
		let reader = new FileReader();
		const file = event.target.files[0];
		setAudioFile(file);
			
		if(!(file.type == 'audio/mpeg' || file.type == 'audio/mp3' || file.type == 'audio/wav' )){
			
			setErrors({...errors,audioTypeError:'Please select audio file type'});
			
		}else{

			setErrors({...errors,audioTypeError:''});
		}
		
		reader.onload = function() {
			
			let media = new Audio(reader.result);
			media.onloadedmetadata = function(){
				let duration = media.duration ;
				
				if(duration >= 600 ){
					
					setErrors({...errors,audioDurationError:'Audio file should be below 10 min duration'});
				}else{
					setErrors({...errors,audioDurationError:''});
					setErrors({...errors,audioTypeError:''});
				}

			};    
		};
		reader.readAsDataURL(file); 
	 };

	
	 
	const onFinish = val => {
		 
		const formData = new FormData();
		if(audioImage){
		formData.append('audio_image', audioImage);
		}
		if(audioFile){
			formData.append('audio_file', audioFile);
		}
		
		formData.append('audio_title', val.audio_name);
		formData.append('location_name', val.location);
		formData.append('description', val.description);
		formData.append('lat',latLng.lat);
		formData.append('lng',latLng.lng);
		formData.append('_id',(props.audios?.detail?.data?.length > 0)?props.audios?.detail?.data[0]._id:'');
		formData.append('isActive',val.isActive);
		formData.append('isPaid',val.isPaid);
		// console.log(val.isActive)
		// for (var value of formData.values()) {
		// 	console.log(value);
		
		//  }
		//  return
		if( !(errors.audioDurationError || errors.audioTypeError||errors.imageTypeError))
			if (props.match.params.id) {
				 
				dispatch({ type: 'audios/EditAudio', payload: formData });
			}else {
				dispatch({ type: 'audios/AddAudio', payload: formData });
			}	
		
	}

	const convertUndefinedObjectKeysToEmptyString = (object) => {
		var output = {};
		for(let i in object) {
			if(!object[i]) {
				output[i] = "";
			} else {
				output[i] = object[i];
			}	
		}
		return output;
	}

	
	const  onMarkerDragEnd = (event) => {

	
		const lat = event.latLng.lat();
		const lng = event.latLng.lng();
		
		const latitudeLongitude = {
			
			lat:lat,
			lng:lng
		}
		setLatLng(latitudeLongitude);
	}; 

	
	const onLoad = useCallback((props) => {
		
		setAutocomplete(props)
	  }, [])
	
	  const onPlaceChanged = () => {
		
		if (autocomplete !== null) {
			
			const latitudeLongitude = {
			
				lat:autocomplete.getPlace().geometry.location.lat(),
				lng:autocomplete.getPlace().geometry.location.lng()
			}
			setLatLng(latitudeLongitude);
			form.setFieldsValue({
				['location']: autocomplete.getPlace().formatted_address
			})
		}
	  }


		return (
		<Card title={<span><LeftOutlined onClick={() => props.history.push('/audios')} /> 
			{ carId ? 'Edit Audio' : 'Add Audio'}</span>} style={{ marginTop: "0" }}>
			
			{ 
				
			(errors.imageTypeError || errors.audioTypeError || errors.audioDurationError ) ?
			
			<Alert
				message="Error "
				description="You are select wrong file type or audio should be below than 10 min."
				type="error"
				closable
				
    		/>:''
			}
			<Form {...formItemLayout} form={form} name="loc_info" layout="vertical" onFinish={onFinish} className="innerFields">
				
				
				<Row gutter={15}>
					<Col sm={24} md={12}>
						
						<Form.Item name="audio_name" label="Audio Name" rules={[{ required: true, message: 'Field required!' },]}  >

							<Input placeholder="Audio Name" />
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={15}>
					<Col sm={24} md={12}>
						
						<Form.Item name="description" label="Description" rules={[{ required: true, message: 'Please enter the description' },]}  >
							<textarea row={8} cols={55}></textarea>
						</Form.Item>
					</Col>
				</Row>
				
				<LoadScript
					googleMapsApiKey="AIzaSyBeSEd28jj7f1LpDCjI-ZowhKp1e8z3q4A"
					libraries={['places']}
					>
				<Autocomplete
								 onLoad={onLoad} onPlaceChanged={onPlaceChanged} bounds={true} onUnmount={true}

							> 

							<Row gutter={15}>
								<Col sm={24} md={12}>
								 <Form.Item name="location" label="Location Name" rules={[{ required: true, message: 'Field required!' },]}  > 
										<Input  placeholder="Location " type="text" />
								 </Form.Item> 
								</Col>
							</Row>
						</Autocomplete> 
				</LoadScript>	
				<Row gutter={15}>
				{
					props.audios?.detail?.data?.length > 0  ?
					<Col sm={24} md={12}>
						<Form.Item name="audio_image" label="Audio Image"  >
							<Input placeholder="Audio Name" type="file" onChange={(e) => onAudioImageChange(e)} />
						</Form.Item>
					</Col> : <Col sm={24} md={12}>
						<Form.Item name="audio_image" label="Audio Image" rules={[{ required: true, message: 'Field required!' },]}  >
							<Input placeholder="Audio Name" type="file" onChange={(e) => onAudioImageChange(e)} />
						</Form.Item>
					</Col> 
				}
					{/* if(!props.audios?.detail?.data?.length > 0 ){
				return
			} */}
					{
						props.audios?.detail?.data?.length > 0  ?  <img style={{ width:100,hieght:50 }} src={props.audios?.detail?.data[0].image} />: null
					}
					
			 	</Row>
				<Row gutter={15}>
				{
						props.audios?.detail?.data?.length > 0  ?
					<Col sm={24} md={12}>
						<Form.Item name="audio_file" label="Audio file"   >
							<Input placeholder="Audio file" type="file"  onChange={(e) =>onAudioChange(e)}  />
						</Form.Item>
					</Col>:<Col sm={24} md={12}>
						<Form.Item name="audio_file" label="Audio file" rules={[{ required: true, message: 'Field required!' },]}  >
							<Input placeholder="Audio file" type="file"  onChange={(e) =>onAudioChange(e)}  />
						</Form.Item>
					</Col>
				}
			    </Row>
				{
					props.audios?.detail?.data?.length > 0  ? <ReactAudioPlayer
					src={props.audios?.detail?.data[0].audio_file}
					autoPlay
					controls
					/> : null
				}
				
				<div style={{ height: '50vh', width: '100%' }}>
				
				<LoadScript
					googleMapsApiKey="AIzaSyBeSEd28jj7f1LpDCjI-ZowhKp1e8z3q4A"
					libraries={['places']}
					>
						<GoogleMap center={latLng} zoom={15} mapContainerStyle={{width:'50%',height:'100%'}}>
							
							<Marker
							//onLoad={onLoad}
							position={latLng}
							draggable={true}
							onDragEnd={(e) => onMarkerDragEnd(e)}
							/>					
						</GoogleMap>
				</LoadScript>
				</div>

				 <Form.Item  name="isActive" valuePropName="checked" >
                  <Checkbox>isActive</Checkbox>
             	 </Form.Item>
				 
				 <Form.Item  name="isPaid" valuePropName="checked" >
                  <Checkbox>Is Paid</Checkbox>
             	 </Form.Item>

				  <Form.Item className="mb-0">
					<Button onClick={cancelFun}>Cancel</Button>&nbsp;&nbsp;
					<Button type="primary" className="btn-w25 btn-primary-light" onClick={() => form.submit()}>Save</Button>
				</Form.Item>
			</Form>
			
			

		</Card>
	)
};

export default connect(({ audios, global, loading }) => ({
	audios: audios,
	global: global
}))(AddEditAudio);