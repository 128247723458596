import React, { useState, useEffect } from "react";
import { Form, Select, Input, Button, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import axios from "axios";

const baseUrl = process.env.REACT_APP_ApiUrl;
const { Option } = Select;

const StateForm = (props) => {
  const [countries, setCountries] = useState([]);

  const [form] = useForm();

  useEffect(() => {
    if (props.statedata._id) {
      console.log("props.statedata", props.statedata.country_id);
      // setCountries(props.statedata.country_id._id);
      form.setFieldsValue({
        // countryId: props.statedata.country_id, // Assuming countryId is the field name for the country
        // countryId: props.statedata.country_id,
        countryId: props.statedata.country_id._id,
        stateName: props.statedata.state_name,
      });
    } else {
      form.resetFields();
    }
  }, [props.statedata, form]);

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/getAlldata/country`);
      setCountries(response.data.data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const handleSubmit = async () => {
    try {
      const values = await form.validateFields(); // Validate form fields

      const requestData = {
        country_id: values.countryId, // Update the country_id field
        state_name: values.stateName,
      };

      let response;

      if (props.statedata._id) {
        // Update existing state
        response = await axios.post(`${baseUrl}/api/state`, {
          id: props.statedata._id,
          ...requestData,
        });

        if (response.data._id) {
          message.success("State updated successfully");
        }
      } else {
        // Create new state
        response = await axios.post(`${baseUrl}/api/state`, requestData);
        if (response.data._id) {
          message.success("State create successfully");
        }
      }

      if (props.returnSuccess) {
        if (props.statedata._id) {
          response.data._id = props.statedata
            ? props.statedata._id
            : response.data._id;
        }
        props.returnSuccess(response.data);
      }

      form.resetFields(); // Clear form fields after submission
    } catch (error) {
      console.error("Error adding/updating state:", error);
      message.error("Duplicate Entry");
    }
  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Form.Item
        label="Country"
        name="countryId"
        rules={[{ required: true, message: "Please select a country" }]}
        // style={{ marginLeft: "20px" }}
      >
        <Select placeholder="Select a country" style={{ width: "100%" }}>
          {countries.map((country) => (
            <Option key={country._id} value={country._id}>
              {country.country_name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="State "
        name="stateName"
        rules={[
          { required: true, message: "Please enter state name" },
          {
            whitespace: true,
            message: "Country name should not be just white spaces",
          },
        ]}
        style={{ marginLeft: "20px" }}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {props.statedata._id ? "Updated State" : "Add State"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default StateForm;
