import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Row, Popconfirm, Input, Col ,Typography} from "antd";
import Addcountry from "./add-edit/addEdit";
import axios from "axios";
const baseUrl = process.env.REACT_APP_ApiUrl;
const { Text } = Typography;
const CountryComponet = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [countries, setCountries] = useState([]);
  const [countrieData, setCountrieData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  
  const [Pagesize, setPagesize] = useState(10);

  const showModal = (country) => {
    setSelectedCountry(country);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setSelectedCountry(null);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setSelectedCountry(null);
    setIsModalVisible(false);
  };

  const handleSuccess = (data) => {
    fetchCountries();

    console.log("data", data);
    setIsModalVisible(false);

    // if (data._id) {
    //   // setSelectedCountry(data.id);

    //   setCountries((pre) =>
    //     pre.map((country) =>
    //       country._id === data._id ? { ...country, ...data } : country
    //     )
    //   );
    // }
    // if (data) {
    //   setCountries((pre) => {
    //     return [...pre, data];
    //   });
    // }
  };

  const fetchCountries = async (page = currentPage, limit = Pagesize) => {
    try {
      const response = await axios.post(`${baseUrl}/api/getAll/country`, {
      
        page,
        limit,
      });

      setCountries(response.data.data);
      setTotalPages(Math.ceil(response.data.count /limit ));
      console.log("countrydata show", response.data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  useEffect(() => {
    fetchCountries();
  }, [currentPage]);
  // useEffect(() => {
  //   fetchCountries();
  // }, []);
  useEffect(() => {
    setCountries();
  }, []);
  useEffect(() => {
    if (searchText == "") {
      setCountrieData(countries);
    } else {
      setCountrieData(countrieData);
    }
  }, [countries, countrieData]);
  const handleDelete = (record) => {
    console.log("Delete", record._id);
    axios
      .delete(`${baseUrl}/api/delete/country`, { data: { id: record._id } })
      .then(() => {
        setCountries((pre) =>
          pre.filter((country) => country.id !== record._id)
        );
        fetchCountries()
      })
      .catch((error) => {
        console.error("Error deleting country:", error);
      });

  };
  const columns = [
    {
      title: "Country Name",
      dataIndex: "country_name",
      key: "country_name",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div>
          <Button type="link" onClick={() => showModal(record)}>
            Edit
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this country?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];
  const handleFilter = (value) => {
    const trimmedValue = value.trim();
    setSearchText(trimmedValue);
    const filteredCountries = countries.filter((country) =>
      country.country_name.toLowerCase().includes(searchText.toLowerCase())
    );
    console.log("countries ====----- :", countries);
    // setCountries(filteredCountries);
    setCountrieData(filteredCountries);
  };
  const handleShowSizeChange = (current, newSize) => {
    const newPage = Math.floor(((current - 1) * Pagesize) / newSize) + 1;

    setCurrentPage(newPage);
    setPagesize(newSize);

    fetchCountries(newPage, newSize);
  };

  return (
    <div>
      <Row justify="space-between" style={{ marginBottom: "10px" }}>
        <Col>
          <Input
            placeholder="Search country name"
            value={searchText}
            onChange={(e) => handleFilter(e.target.value)}
          />
        </Col>
        <Col>
          <Button type="primary" onClick={showModal}>
            Add
          </Button>
        </Col>
      </Row>
      <Modal
        title=""
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Addcountry returnSuccess={handleSuccess} country={selectedCountry} />
      </Modal>
      <Table
        dataSource={countrieData}
        columns={columns}
        rowKey="_id"

        pagination={{
          position: ["bottomLeft"],
          showTotal: (total, range) => (
            <Text type="secondary">{`Showing ${range[0]}-${range[1]} of ${total}`}</Text>
          ),
          showSizeChanger: true,
          responsive: true,
          onShowSizeChange: handleShowSizeChange,
          pageSizeOptions: ["20", "30", "40", "50", "100", "200", "500"],
          pageSize: Pagesize,
          current: currentPage,
          total: totalPages * Pagesize,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setPagesize(pageSize);
            // fetchStates(page, pageSize);
          },
        }}
        // pagination={false}
        // pagination={{
        //   pageSize: 5,
        //   current: currentPage,
        //   total: totalPages * 10,
        //   onChange: (page) => setCurrentPage(page),
        // }}
      />
    </div>
  );
};

export default CountryComponet;
