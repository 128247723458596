import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';

const SupportForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    $.ajax({
      type: 'POST',
      url: 'https://thesyllabestapp.app:8080/api/add-support',
      data: formData,
      success: (response) => {
        alert('Thank you! Your message has been sent successfully.');
        setFormData({
          name: '',
          email: '',
          message: ''
        });
      },
      error: (xhr, status, error) => {
        alert('Error: ' + xhr.responseJSON.message);
      }
    });
  };

  return (
    <div className="BODY" style={{ backgroundColor: '#08759927', backgroundImage: 'url("https://cdnmedia.mapei.com/images/background-image/bg-assistenza.jpg?sfvrsn=b795777c_7")', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '120vh', margin: 0 }}>
      <div className="container" style={{ maxWidth: '600px', margin: '0 auto', padding: '30px', backgroundColor: '#fff', borderRadius: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '15rem' }}>
          <img src="https://thesyllabestapp.app/static/media/logo.b9c0a52b.png" style={{ height: '60px', width: '60px' }} alt="Logo" />
        </div>
        <h1 style={{ textAlign: 'center' }}>Get in touch with us</h1>
        <h6 style={{ textAlign: 'center', marginBottom: '30px', color: 'gray', fontWeight: '300', fontSize: 'medium' }}>Could you please provide your contact information so we can get in touch with you?</h6>
        <form id="supportForm" onSubmit={handleSubmit}>
          <div className="form-group" >
            <label htmlFor="name">Name</label>
            <input style={{width:"163%"}} type="text" className="form-control" id="name" placeholder="Enter your name" name="name" value={formData.name} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input style={{width:"163%"}} type="email" className="form-control" id="email" placeholder="Enter your email" name="email" value={formData.email} onChange={handleChange} required />
            <div className="invalid-feedback" id="email-error"></div>
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea style={{width:"163%"}} className="form-control" id="message" name="message" placeholder="Enter your message" rows="5" value={formData.message} onChange={handleChange} required></textarea>
          </div>
          <div style={{ height: '20px' }}></div>
          <button type="submit" style={{ width: '100%', padding: '13px', border: 'none', borderRadius: '15px', backgroundColor: '#43b2df', color: '#fff', cursor: 'pointer',marginLeft:"8rem" }}>Submit</button>
        </form>
        {responseMessage && <div id="responseMessage" className="mt-3">{responseMessage}</div>}
      </div>
    </div>
  );
};

export default SupportForm;
