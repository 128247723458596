import React, { Component, Suspense, lazy } from "react";
import { Route } from "react-router-dom";
import { Redirect } from "dva/router";
import Dashboard from "../../pages/dashboard";

import UsersList from "../../pages/users/list";
import AddEditUser from "../../pages/users/action/addEdit";

//route for audio list
import AudioList from "../../pages/audios/list";
import AddEditAudio from "../../pages/audios/action/addEditAudio";

//route for notification and page list
import Notifications from "../../pages/notifications";
import PagesList from "../../pages/pages/list";
import Faq from "../../pages/FAQ/list";
import AddEditFaq from "../../pages/FAQ/actions/addEdit";

import AddEditPages from "../../pages/pages/action/addEdit";
//route for blog
import BlogsList from "../../pages/blogs/list";
import AddEditBlogs from "../../pages/blogs/action/addEdit";

//contact and sitesetting
import Contact from "../../pages/contact/contact";
import SiteSetting from "../../pages/site-setting/list";
import Country from "../../pages/country/country";
import State from "../../pages/statecuntry/state";
import City from "../../pages/city/city";
import Settings from "../../pages/setting/setting";
import support from "../../pages/Support/list";
import deleteRequest from "../../pages/Delete-request/list";
import EventPage from './../StaticPages/Privacy';
import SupportForm from './../StaticPages/SupportPage';


function hasAdmin() {
  let role = localStorage.getItem("role");
  if (role === "ADMIN") {
    return true;
  } else {
    return false;
  }
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      hasAdmin() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

class SubRoute extends Component {
  render() {
    return (
      <div>
        {/* Dashboard */}
        <Route
          exact
          name="Dashboad"
          breadcrumbName="Dashboad"
          path={"/"}
          component={Dashboard}
        />

        {/* User List */}
        <PrivateRoute exact path="/users" component={UsersList} />
        <PrivateRoute exact path="/users/edit/:id" component={AddEditUser} />

        <Route exact path={"/pages"} component={PagesList} />
        <Route exact path={"/pages/add"} component={AddEditPages} />

        {/* <Route exact path={"/audios"} component={AudioList} />
        <Route exact path={"/audios/add"} component={AddEditAudio} />
        <Route exact path={"/audios/edit/:id"} component={AddEditAudio} /> */}

        <PrivateRoute exact path="/pages/edit/:id" component={AddEditPages} />
        <Route exact path={"/blogs"} component={BlogsList} />
        <Route exact path={"/blogs/add"} component={AddEditBlogs} />
        <PrivateRoute exact path="/blogs/edit/:id" component={AddEditBlogs} />
        <Route exact path={`/notification`} component={Notifications} />

        <Route exact path={"/support-list"} component={support} />
        <Route exact path={"/delete-request"} component={deleteRequest} />

        {/* <Route exact path='/privacy-policy' component={EventPage} />	 */}

        <Route exact path={`/contact`} component={Contact} />
        <Route exact path={`/setting`} component={SiteSetting} />
        <Route exact path={"/FAQ"} component={Faq} />
        <Route exact path={"/FAQ/add"} component={AddEditFaq} />
        <Route exact path={"/FAQ/edit/:id"} component={AddEditFaq} />

        <Route exact path={"/country"} component={Country} />
        <Route exact path={"/city"} component={City} />
        <Route exact path={"/state"} component={State} />
      </div>
    );
  }
}

export default SubRoute;
