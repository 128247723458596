import React, { useState, useEffect } from "react";
import StateComponents from "./add-edit/addEdit";
import {
  Input,
  Button,
  Select,
  Modal,
  Table,
  Row,
  Popconfirm,
  Col,
  Typography

} from "antd";
import axios from "axios";
const baseUrl = process.env.REACT_APP_ApiUrl;

const { Option } = Select;
const { Text } = Typography; 




const StateComponent = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedState, setSelectedState] = useState(null);

  const [states, setStates] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10)

  useEffect(() => {
    fetchStates();
  }, [currentPage]);
  useEffect(() => {
    setStates();
  }, []);
  useEffect(() => {
    if (searchText == "") {
      setStateData(states);
    } else {
      setStateData(stateData);
    }
  }, [states, stateData]);

  const fetchStates = async (page = currentPage, limit = pageSize) => {
    try {
      const response = await axios.post(`${baseUrl}/api/getAll/state`, {
        // page: currentPage,
        // limit: 10,
        page,
        limit,
      });

      setStates(response.data.data);
      console.log(response.data.count, "state data chacked");
      setTotalPages(Math.ceil(response.data.count / limit));
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const handleSuccess = (data) => {
    console.log("data", data);
    fetchStates();
    setIsModalVisible(false);
  };

  const showModal = (state) => {
    setSelectedState(state);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setSelectedState(null);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setSelectedState(null);
    setIsModalVisible(false);
  };
  useEffect(() => {
    setStates();
  }, []);
  const handleDelete = (record) => {
    console.log("Delete", record._id);
    axios
      .delete(`${baseUrl}/api/delete/state`, { data: { id: record._id } })
      .then(() => {
        setStates((pre) => pre.filter((states) => states.id !== record._id));
        fetchStates();
      })
      .catch((error) => {
        console.error("Error deleting country:", error);
      });
  };

  const columns = [
    {
      title: "Country",
      // dataIndex: "country_id.country_name", // Access nested property
      key: "country_name",
      render: (text, data) => {
        return <span>{data?.country_id?.country_name}</span>;
      },
    },

    {
      title: "State Name",
      dataIndex: "state_name",
      key: "name",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <span>
          <Button type="link" onClick={() => showModal(record)}>
            Edit
          </Button>

          <Popconfirm
            title="Are you sure you want to delete this country?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  const handleFilter = (value) => {
    const trimmedValue = value.trim();
    console.log(states._id, "statedata");
    setSearchText(trimmedValue);
    const filteredStates = states.filter(
      (data) =>
        data.state_name.toLowerCase().includes(searchText.toLowerCase()) ||
        data.country_id.country_name
          .toLowerCase()
          .includes(searchText.toLowerCase())
    );

    setStateData(filteredStates);
  };
  const handleShowSizeChange = (current, newSize) => {
    const newPage = Math.floor((current - 1) * pageSize / newSize) + 1;

    // Update the current page and page size
    setCurrentPage(newPage);
    setPageSize(newSize);
  
    // Fetch data for the new page and size
    fetchStates(newPage, newSize);
    
  };
  
  


  return (
    <div>
      <Row justify="space-between" style={{ marginBottom: "10px" }}>
        <Col>
          <Input
            placeholder="Search State name"
            value={searchText}
            onChange={(e) => handleFilter(e.target.value)}
          />
        </Col>
        <Col>
          <Button type="primary" onClick={showModal}>
            Add
          </Button>
        </Col>
      </Row>
      <Modal
        title=""
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <StateComponents
          returnSuccess={handleSuccess}
          statedata={selectedState}
        />
      </Modal>
      <Table
        dataSource={stateData}
        columns={columns}
        pagination={{
          position: ["bottomLeft"],
          showTotal: (total, range) => (
            <Text type="secondary">{`Showing ${range[0]}-${range[1]} of ${total}`}</Text>
          ),
          showSizeChanger: true,
          responsive: true,
          onShowSizeChange: handleShowSizeChange, // Replace this with your actual function
          pageSizeOptions: ["20", "30", "40", "250", "500"],
          pageSize: pageSize, // Set your desired default page size
          current: currentPage,
          total: totalPages * pageSize, // Assuming states.length is the total count of items
          // onChange: (page) => setCurrentPage(page), // Update current page when changed
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
            // fetchStates(page, pageSize);
          },
        }}
      />

      {/* <Table
        dataSource={stateData}
        columns={columns}
        // pagination={false}
        pagination={{
          pageSize: 10,
          current: currentPage,
          total: totalPages * 10, // Assuming states.length is the total count of items
          onChange: (page) => setCurrentPage(page), // Update current page when changed
        }}
      /> */}
    </div>
  );
};

export default StateComponent;
