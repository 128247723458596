import React from "react";
import { Router, Route, Switch, Redirect } from "dva/router";
import { BrowserRouter, HashRouter } from "react-router-dom";
import BasicLayout from "./layout/BasicLayout";
import AppLogin from "./layout/login";
import AppTest from "./layout/test";
import AppRegister from "./layout/register";
import AppReset from "./layout/reset";
import AppVarify from "./layout/varify";
import EventPage from "./components/StaticPages/Privacy";
import SupportForm from "./components/StaticPages/SupportPage";

function hasLogin() {
  let token = localStorage.getItem("token");
  let user = localStorage.getItem("user");
  const objUser = JSON.parse(user);

  if (token && objUser) {
    return true;
  } else {
    return false;
  }
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      hasLogin() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
);

function RouterConfig() {
  const isSupportRoute = window.location.pathname == `/support`;
  console.log("isSupportRoute", isSupportRoute, window.location.pathname);
  return isSupportRoute ? (
    <BrowserRouter>
      <Switch>
        <Route path="/support" component={SupportForm} />
      </Switch>
    </BrowserRouter>
  ) : (
    <HashRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route exact path="/login" component={AppLogin} />
        <Route exact path="/verify" component={AppVarify} />
        {/* <Route exact path="/privacy-policy" component={EventPage} /> */}
        <Route path="/support" component={SupportForm} />
        <Route exact path="/test" component={AppTest} />
        <Route exact path="/register" component={AppRegister} />
        <Route exact path="/reset" component={AppReset} />
        <PrivateRoute path="/" component={BasicLayout} />

      </Switch>
    </HashRouter>
  );
}

export default RouterConfig;
