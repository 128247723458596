import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "popper.js/dist/popper.min.js";
import "bootstrap/dist/js/bootstrap.min.js";

const EventPage = ({ data }) => {
  return (
    <div
      className="BODY"
      style={{
        backgroundColor: "lightgrey",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="BODY">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="text-left font-weight-bold mt-5 mb-3">
                Privacy Policy
              </h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p></p>
              <p>PRIVACY POLICY</p>
              <p>&nbsp;</p>
              <p>
                This privacy policy ("Policy") describes how the personally
                identifiable information ("Personal Information") you may
                provide in the "Syllabest" mobile application ("Mobile
                Application" or "Service") and any of its related products and
                services (collectively, "Services") is collected, protected and
                used. It also describes the choices available to you regarding
                our use of your Personal Information and how you can access and
                update this information. This Policy is a legally binding
                agreement between you ("User", "you" or "your") and Syllabest
                ("Syllabest", "we", "us" or "our"). By accessing and using the
                Mobile Application and Services, you acknowledge that you have
                read, understood, and agree to be bound by the terms of this
                Agreement. This Policy does not apply to the practices of
                companies that we do not own or control, or to individuals that
                we do not employ or manage.
              </p>
              <p>&nbsp;</p>
              <p>Automatic collection of information</p>
              <p>&nbsp;</p>
              <p>
                When you use the Mobile Application, our servers automatically
                record information that your device sends. This data may include
                information such as your device's IP address and location,
                device name and version, operating system type and version,
                language preferences, information you search for in the Mobile
                Application, access times and dates, and other statistics.
              </p>
              <p>&nbsp;</p>
              <p>
                Information collected automatically is used only to identify
                potential cases of abuse and statistical information regarding
                the usage of the Mobile Application and Services. This
                statistical information is not otherwise aggregated in such a
                way that would identify any particular user of the system.
              </p>
              <p>&nbsp;</p>
              <p>Collection of personal information</p>
              <p>&nbsp;</p>
              <p>
                You can access and use the Mobile Application and Services
                without telling us who you are or revealing any information by
                which someone could identify you as a specific, identifiable
                individual. If, however, you wish to use some of the features in
                the Mobile Application, you may be asked to provide certain
                Personal Information (for example, your name and e-mail
                address). We receive and store any information you knowingly
                provide to us when you create an account, publish content, make
                a purchase, or fill any online forms in the Mobile Application.
                When required, this information may include the following:
              </p>
              <p>&nbsp;</p>
              <p>- Personal details such as name, country of residence, etc.</p>
              <p>&nbsp;</p>
              <p>- Contact information such as email address, address, etc.</p>
              <p>&nbsp;</p>
              <p>
                - Account details such as user name, unique user ID, password,
                etc.
              </p>
              <p>&nbsp;</p>
              <p>
                - Payment information such as credit card details, bank details,
                etc.
              </p>
              <p>&nbsp;</p>
              <p>- Mic access for sending audio messages in the app.&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>
                - Certain features on the mobile device such as contacts,
                calendar, gallery, etc.
              </p>
              <p>&nbsp;</p>
              <p>
                - Any other materials you willingly submit to us such as
                articles, images, feedback, etc.
              </p>
              <p>&nbsp;</p>
              <p>
                You can choose not to provide us with your Personal Information,
                but then you may not be able to take advantage of some of the
                features in the Mobile Application. Users who are uncertain
                about what information is mandatory are welcome to contact us.
              </p>
              <p>&nbsp;</p>
              <p>Use and processing of collected information</p>
              <p>&nbsp;</p>
              <p>
                In order to make the Mobile Application and Services available
                to you, or to meet a legal obligation, we need to collect and
                use certain Personal Information. If you do not provide the
                information that we request, we may not be able to provide you
                with the requested products or services. Some of the information
                we collect is directly from you via the Mobile Application and
                Services. However, we may also collect Personal Information
                about you from other sources such as social media, emails, etc.
                Any of the information we collect from you may be used for the
                following purposes:
              </p>
              <p>&nbsp;</p>
              <p>- Create and manage user accounts</p>
              <p>&nbsp;</p>
              <p>- Fulfill and manage orders</p>
              <p>&nbsp;</p>
              <p>- Deliver products or services</p>
              <p>&nbsp;</p>
              <p>- Improve products and services</p>
              <p>&nbsp;</p>
              <p>- Send administrative information</p>
              <p>&nbsp;</p>
              <p>- Send marketing and promotional communications</p>
              <p>&nbsp;</p>
              <p>- Respond to inquiries and offer support</p>
              <p>&nbsp;</p>
              <p>- Request user feedback</p>
              <p>&nbsp;</p>
              <p>- Improve user experience</p>
              <p>&nbsp;</p>
              <p>- Post customer testimonials</p>
              <p>&nbsp;</p>
              <p>- Deliver targeted advertising</p>
              <p>&nbsp;</p>
              <p>- Enforce terms and conditions and policies</p>
              <p>&nbsp;</p>
              <p>- Protect from abuse and malicious users</p>
              <p>&nbsp;</p>
              <p>- Respond to legal requests and prevent harm</p>
              <p>&nbsp;</p>
              <p>- Run and operate the Mobile Application and Services</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>
                Processing your Personal Information depends on how you interact
                with the Mobile Application and Services, where you are located
                in the world and if one of the following applies: (i) you have
                given your consent for one or more specific purposes; this,
                however, does not apply, whenever the processing of Personal
                Information is subject to California Consumer Privacy Act or
                European data protection law; (ii) provision of information is
                necessary for the performance of an agreement with you and/or
                for any pre-contractual obligations thereof; (iii) processing is
                necessary for compliance with a legal obligation to which you
                are subject; (iv) processing is related to a task that is
                carried out in the public interest or in the exercise of
                official authority vested in us; (v) processing is necessary for
                the purposes of the legitimate interests pursued by us or by a
                third party.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>
                Note that under some legislations we may be allowed to process
                information until you object to such processing (by opting out),
                without having to rely on consent or any other of the following
                legal bases below. In any case, we will be happy to clarify the
                specific legal basis that applies to the processing, and in
                particular whether the provision of Personal Information is a
                statutory or contractual requirement, or a requirement necessary
                to enter into a contract.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>Billing and payments</p>
              <p>&nbsp;</p>
              <p>
                In case of services requiring payment, we request credit card or
                other payment account information, which will be used solely for
                processing payments. Your purchase transaction data is stored
                only as long as is necessary to complete your purchase
                transaction. After that is complete, your purchase transaction
                information is deleted. Where necessary for processing future
                payments and subject to your prior consent, your financial
                information will be stored in encrypted form on secure servers
                of our reputed payment gateway service provider who is beholden
                to treating your Personal Information in accordance with this
                Policy. All direct payment gateways adhere to the latest
                security standards as managed by the PCI Security Standards
                Council, which is a joint effort of brands like Visa,
                MasterCard, American Express and Discover.Â&nbsp; Sensitive and
                private data exchange happens over a SSL secured communication
                channel and is encrypted and protected with digital signatures,
                and the Mobile Application and Services are also in compliance
                with PCI vulnerability standards in order to create as secure of
                an environment as possible for Users. Scans for malware are
                performed on a regular basis for additional security and
                protection.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>Managing information</p>
              <p>&nbsp;</p>
              <p>
                You are able to delete certain Personal Information we have
                about you. The Personal Information you can delete may change as
                the Mobile Application and Services change. When you delete
                Personal Information, however, we may maintain a copy of the
                unrevised Personal Information in our records for the duration
                necessary to comply with our obligations to our affiliates and
                partners, and for the purposes described below. If you would
                like to delete your Personal Information or permanently delete
                your account, you can do so on the settings page of your account
                in the Mobile Application.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>Disclosure of information</p>
              <p>&nbsp;</p>
              <p>
                Depending on the requested Services or as necessary to complete
                any transaction or provide any service you have requested, we
                may share your information with your consent with our trusted
                third parties that work with us, any other affiliates and
                subsidiaries we rely upon to assist in the operation of the
                Mobile Application and Services available to you. We do not
                share Personal Information with unaffiliated third parties.
                These service providers are not authorized to use or disclose
                your information except as necessary to perform services on our
                behalf or comply with legal requirements. We may share your
                Personal Information for these purposes only with third parties
                whose privacy policies are consistent with ours or who agree to
                abide by our policies with respect to Personal Information.
                These third parties are given Personal Information they need
                only in order to perform their designated functions, and we do
                not authorize them to use or disclose Personal Information for
                their own marketing or other purposes.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>
                We will disclose any Personal Information we collect, use or
                receive if required or permitted by law, such as to comply with
                a subpoena, or similar legal process, and when we believe in
                good faith that disclosure is necessary to protect our rights,
                protect your safety or the safety of others, investigate fraud,
                or respond to a government request.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>
                In the event we go through a business transition, such as a
                merger or acquisition by another company, or sale of all or a
                portion of its assets, your user account, and Personal
                Information will likely be among the assets transferred.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>Retention of information</p>
              <p>&nbsp;</p>
              <p>
                We will retain and use your Personal Information for the period
                necessary to comply with our legal obligations, resolve
                disputes, and enforce our agreements unless a longer retention
                period is required or permitted by law. We may use any
                aggregated data derived from or incorporating your Personal
                Information after you update or delete it, but not in a manner
                that would identify you personally. Once the retention period
                expires, Personal Information shall be deleted. Therefore, the
                right to access, the right to erasure, the right to
                rectification and the right to data portability cannot be
                enforced after the expiration of the retention period.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>End User license Agreement</p>
              <p>&nbsp;</p>
              <p>
                Every User must need to accept the End user license Agreement
                (EULA) in order to register themselves on the application. We do
                not tolerate any objectionable content or abusive users and
                users will be blocked from the application. User must accept the
                agreement before signing up on the application. This agreement
                will be present in the form of Tick Mark on the signup screen.
                Accepting the EULA means that any form of intolerable content
                will not be accepted. User generated content must not be
                abusive. It includes posting images, adding reviews, comments
                any any other form of content which might harm other user’s
                sentiments. User can anytime report such form of content and
                admin will have the full rights to directly deactivate that
                user’s account.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>The rights of users</p>
              <p>&nbsp;</p>
              <p>
                You may exercise certain rights regarding your information
                processed by us. In particular, you have the right to do the
                following: (i) you have the right to withdraw consent where you
                have previously given your consent to the processing of your
                information; (ii) you have the right to object to the processing
                of your information if the processing is carried out on a legal
                basis other than consent; (iii) you have the right to learn if
                information is being processed by us, obtain disclosure
                regarding certain aspects of the processing and obtain a copy of
                the information undergoing processing; (iv) you have the right
                to verify the accuracy of your information and ask for it to be
                updated or corrected; (v) you have the right, under certain
                circumstances, to restrict the processing of your information,
                in which case, we will not process your information for any
                purpose other than storing it; (vi) you have the right, under
                certain circumstances, to obtain the erasure of your Personal
                Information from us; (vii) you have the right to receive your
                information in a structured, commonly used and machine readable
                format and, if technically feasible, to have it transmitted to
                another controller without any hindrance. This provision is
                applicable provided that your information is processed by
                automated means and that the processing is based on your
                consent, on a contract which you are part of or on
                pre-contractual obligations thereof.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>California privacy rights</p>
              <p>&nbsp;</p>
              <p>
                In addition to the rights as explained in this Policy,
                California residents who provide Personal Information (as
                defined in the statute) to obtain products or services for
                personal, family, or household use are entitled to request and
                obtain from us, once a calendar year, information about the
                Personal Information we shared, if any, with other businesses
                for marketing uses. If applicable, this information would
                include the categories of Personal Information and the names and
                addresses of those businesses with which we shared such personal
                information for the immediately prior calendar year (e.g.,
                requests made in the current year will receive information about
                the prior year). To obtain this information please contact us.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>How to exercise these rights</p>
              <p>&nbsp;</p>
              <p>
                Any requests to exercise your rights can be directed toSyllabest
                llc through the contact details provided in this document.
                Please note that we may ask you to verify your identity before
                responding to such requests. Your request must provide
                sufficient information that allows us to verify that you are the
                person you are claiming to be or that you are the authorized
                representative of such person. You must include sufficient
                details to allow us to properly understand the request and
                respond to it. We cannot respond to your request or provide you
                with Personal Information unless we first verify your identity
                or authority to make such a request and confirm that the
                Personal Information relates to you.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>Privacy of children</p>
              <p>&nbsp;</p>
              <p>
                We do not knowingly collect any Personal Information from
                children under the age of 18. If you are under the age of 18,
                please do not submit any Personal Information through the Mobile
                Application and Services. We encourage parents and legal
                guardians to monitor their children's Internet usage and to help
                enforce this Policy by instructing their children never to
                provide Personal Information through the Mobile Application and
                Services without their permission. If you have reason to believe
                that a child under the age of 18 has provided Personal
                Information to us through the Mobile Application and Services,
                please contact us. You must also be old enough to consent to the
                processing of your Personal Information in your country (in some
                countries we may allow your parent or guardian to do so on your
                behalf).
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>Advertisements</p>
              <p>&nbsp;</p>
              <p>
                We may permit certain third party companies to help us tailor
                advertising that we think may be of interest to users and to
                collect and use other data about user activities in the Mobile
                Application. These companies may deliver ads that might place
                cookies and otherwise track user behavior.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>Affiliates</p>
              <p>&nbsp;</p>
              <p>
                We may disclose information about you to our affiliates for the
                purpose of being able to offer you related or additional
                products and services. Any information relating to you that we
                provide to our affiliates will be treated by those affiliates in
                accordance with the terms of this Policy.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>Email marketing</p>
              <p>&nbsp;</p>
              <p>
                We offer electronic newsletters to which you may voluntarily
                subscribe at any time. We are committed to keeping your e-mail
                address confidential and will not disclose your email address to
                any third parties except as allowed in the information use and
                processing section or for the purposes of utilizing a third
                party provider to send such emails. We will maintain the
                information sent via e-mail in accordance with applicable laws
                and regulations.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>
                In compliance with the CAN-SPAM Act, all e-mails sent from us
                will clearly state who the e-mail is from and provide clear
                information on how to contact the sender. You may choose to stop
                receiving our newsletter or marketing emails by following the
                unsubscribe instructions included in these emails or by
                contacting us. However, you will continue to receive essential
                transactional emails.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>Links to other resources</p>
              <p>&nbsp;</p>
              <p>
                The Mobile Application and Services contain links to other
                resources that are not owned or controlled by us. Please be
                aware that we are not responsible for the privacy practices of
                such other resources or third parties. We encourage you to be
                aware when you leave the Mobile Application and Services and to
                read the privacy statements of each and every resource that may
                collect Personal Information.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>Information security</p>
              <p>&nbsp;</p>
              <p>
                We secure information you provide on computer servers in a
                controlled, secure environment, protected from unauthorized
                access, use, or disclosure. We maintain reasonable
                administrative, technical, and physical safeguards in an effort
                to protect against unauthorized access, use, modification, and
                disclosure of Personal Information in its control and custody.
                However, no data transmission over the Internet or wireless
                network can be guaranteed. Therefore, while we strive to protect
                your Personal Information, you acknowledge that (i) there are
                security and privacy limitations of the Internet which are
                beyond our control; (ii) the security, integrity, and privacy of
                any and all information and data exchanged between you and the
                Mobile Application and Services cannot be guaranteed; and (iii)
                any such information and data may be viewed or tampered with in
                transit by a third party, despite best efforts.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>Subscription</p>
              <p>&nbsp;</p>
              <p>
                Syllabest allows students to solely focus on their work by
                merging and organizing all due dates into one convenient place
                for immediate access. It also helps students communicate with
                their peers and navigate through their college experience.
                Syllabest is a loyal friend who wants to see you succeed and is
                always there rooting for you. Syllabest helps students do their
                best.
              </p>
              <p>Subscription details are as follows:</p>
              <p>
                Purchase a subscription to access all content and remove ads
              </p>
              <p>&nbsp;</p>
              <p>Price: $1.99/Monthly</p>
              <p>
                * These prices are in dollars ($) for customers located in
                Canada and USA.
              </p>
              <p>
                * Pricing in other countries may vary due to conversions to your
                local currency and other factors.
              </p>
              <p>
                * The subscription will be renewed automatically if it is not
                canceled within at least 24 hours prior to the expiration of the
                current subscription.
              </p>
              <p>
                * The app has a free trial of 3 days which gives users the
                access to entire app when they register.&nbsp;
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>Data breach</p>
              <p>&nbsp;</p>
              <p>
                In the event we become aware that the security of the Mobile
                Application and Services has been compromised or users Personal
                Information has been disclosed to unrelated third parties as a
                result of external activity, including, but not limited to,
                security attacks or fraud, we reserve the right to take
                reasonably appropriate measures, including, but not limited to,
                investigation and reporting, as well as notification to and
                cooperation with law enforcement authorities. In the event of a
                data breach, we will make reasonable efforts to notify affected
                individuals if we believe that there is a reasonable risk of
                harm to the user as a result of the breach or if notice is
                otherwise required by law. When we do, we will post a notice in
                the Mobile Application, send you an email.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>Changes and amendments</p>
              <p>&nbsp;</p>
              <p>
                We reserve the right to modify this Policy or its terms relating
                to the Mobile Application and Services from time to time in our
                discretion and will notify you of any material changes to the
                way in which we treat Personal Information. When we do, we will
                revise the updated date at the bottom of this page. We may also
                provide notice to you in other ways in our discretion, such as
                through contact information you have provided. Any updated
                version of this Policy will be effective immediately upon the
                posting of the revised Policy unless otherwise specified. Your
                continued use of the Mobile Application and Services after the
                effective date of the revised Policy (or such other act
                specified at that time) will constitute your consent to those
                changes. However, we will not, without your consent, use your
                Personal Information in a manner materially different than what
                was stated at the time your Personal Information was collected.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>Acceptance of this policy</p>
              <p>&nbsp;</p>
              <p>
                You acknowledge that you have read this Policy and agree to all
                its terms and conditions. By accessing and using the Mobile
                Application and Services you agree to be bound by this Policy.
                If you do not agree to abide by the terms of this Policy, you
                are not authorized to access or use the Mobile Application and
                Services.
              </p>
              <p>&nbsp;</p>
              <p>Contacting us</p>
              <p>&nbsp;</p>
              <p>
                If you would like to contact us to understand more about this
                Policy or wish to contact us concerning any matter relating to
                individual rights and your Personal Information, you may do so
                via the contact form or send an email to chano@syllabest.net.
              </p>
              <p>&nbsp;</p> <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventPage;
