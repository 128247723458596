import React, { useState, useEffect } from "react";

import axios from "axios";
import { Form, Input, Button, message } from "antd";
const baseUrl = process.env.REACT_APP_ApiUrl;

function CountryForm(props) {
  const [form] = Form.useForm();
  const [countryName, setCountryName] = useState("");

  useEffect(() => {
    if (props.country) {
      form.setFieldsValue({
        countryName: props.country.country_name,
      });
    }
  }, [props.country, form]);
  const handleCountryNameChange = (value) => {
    // Replace multiple consecutive spaces with a single space
    const cleanedValue = value.replace(/\s+/g, " ");
    setCountryName(cleanedValue);

    // Trigger validation for the specific field
    form.validateFields(["countryName"]);
  };

  const handleSubmit = async (values) => {
    try {
      const requestData = {
        country_name: values.countryName,
      };

      let res;

      if (props.country._id) {
        // Update existing country
        res = await axios.post(`${baseUrl}/api/country/`, {
          id: props.country._id,

          ...requestData,
        });

        message.success("Country updated successfully");
      } else {
        res = await axios.post(`${baseUrl}/api/country`, requestData);

        message.success("Country created successfully");
      }

      // Notify the parent component about the success
      if (props.returnSuccess) {
        props.returnSuccess(res.data);
      }

      form.resetFields();
    } catch (error) {
      message.error("Duplicate Entry");
    }
  };

  return (
    <div>
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name="countryName"
          label="Country Name"
          rules={[
            { required: true, message: "Please enter a country name" },
            {
              whitespace: true,
              message: "Country name should not be just white spaces",
            },
          ]}
        >
          <Input
            placeholder="Enter country name"
            onKeyPress={(e) => {
              if (e.key === " ") {
                e.preventDefault(); // Prevent the entry of space character
              }
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {props.country._id ? "Upadate Country" : "Create Country"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default CountryForm;
